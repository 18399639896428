<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Menu - zápatí - seřadit</strong>
      </div>
      <action-tools :routerOptions="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6" v-if="treeData.length !== 0">
          <a-button @click="handleCollapseAll">Sbalit vše
            <a-icon type="shrink"/>
          </a-button>
          <a-button @click="handleExpandAll" class="ml-3">Rozbalit vše
            <a-icon type="arrows-alt"/>
          </a-button>
        </div>
      </div>
      <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-tree
            @drop="handleDrop"
            draggable
            :treeData="treeData"
            :expandedKeys="expandedKeys"
            class="draggable-tree"
            @expand="handleExpand"
          >
          </a-tree>
          <a-empty v-if="treeData.length === 0" />
        </a-form-item>

        <div class="form-actions">
          <a-button
            :disabled="loading"
            type="primary"
            htmlType="submit"
          >Seřadit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      form: this.$form.createForm(this),
      treeData: [],
      loading: false,
      expandedKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/menu/footer/add',
          title: 'Přidat',
        },
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/menu/footer',
          title: 'Seznam',
        },
      ],
    }
  },
  computed: {
    menuFooters: function () {
      return this.$store.getters['menuFooter/currentLanguageSort']
    },
  },
  methods: {
    handleExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },
    handleExpandAll() {
      this.expandedKeys = this.treeData.map(x => x.key)
    },
    handleCollapseAll() {
      this.expandedKeys = []
    },
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      this.$store.dispatch('menuFooter/putSort', this.treeData.map(x => { return { mfe_id: parseInt(x.key.replace('mfe_id:', '')), menus: x.children.map(y => { return { meu_id: y.key } }) } }))
        .then(() => {
          this.handleRefresh()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    toTreeNodeData(data) {
      return data.map(x => {
        return {
          isParent: true,
          key: 'mfe_id:' + x.mfe_id,
          title: '#' + x.mfe_id + ' | ' + (x.languages[0] !== undefined ? x.languages[0].pivot.mfe_title : 'není nenastaveno'),
          children: x.menus === undefined ? [] : x.menus.map(y => {
            return {
              isParent: false,
              key: y.meu_id,
              title: '#' + y.meu_id + ' | ' + (y.menu_languages[0] !== undefined ? y.menu_languages[0].pivot.mle_menu_title : 'není nenastaveno'),
              children: [],
              selectable: false,
            }
          }),
          selectable: false,
        }
      })
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('menuFooter/getList')
        .catch(() => {})
        .finally(() => {
          this.treeData = this.toTreeNodeData(this.menuFooters)
          this.loading = false
        })
    },
    loop(isLeaf, data, key, callback) {
      const currentIsLeaf = isLeaf
      return data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(currentIsLeaf, item, index, arr)
        }
        if (item.children !== undefined && item.children.length > 0) {
          const currentIsLeaf = true
          return this.loop(currentIsLeaf, item.children, key, callback)
        }
      })
    },
    handleDrop(info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey

      let dragObj
      let spliceObjArr
      let spliceObjIndex
      let shouldSplice = true
      this.loop(false, this.treeData, dragKey, (isLeaf, item, index, arr) => {
        spliceObjArr = arr
        spliceObjIndex = index
        dragObj = item
      })
      if (dragObj.isParent) return false

      if (!info.dropToGap) {
        if ((dragObj.children || []).length > 0) {
          return false
        }
        shouldSplice = false
        this.loop(false, this.treeData, dropKey, (isLeaf, item) => {
          if (!isLeaf) {
            spliceObjArr.splice(spliceObjIndex, 1)
            item.children = item.children || []
            item.children.push(dragObj)
          }
        })
      } else {
        let ar
        let i
        let isL = true
        this.loop(false, this.treeData, dropKey, (isLeaf, item, index, arr) => {
          ar = arr
          i = index
          isL = isLeaf
        })
        shouldSplice = false
        if (isL) {
          spliceObjArr.splice(spliceObjIndex, 1)
          ar.splice(i, 0, dragObj)
        }
      }
      if (shouldSplice) {
        spliceObjArr.splice(spliceObjIndex, 1)
      }
    },
  },
  created() {
    if (this.treeData.length === 0 && this.menuFooters.length !== 0) {
      this.treeData = this.toTreeNodeData(this.menuFooters)
    }
    if (this.menuFooters.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.menuFooters.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
  watch: {
    menuFooters() {
      this.treeData = this.toTreeNodeData(this.menuFooters)
    },
  },
}
</script>

<style lang="scss" module scoped>

</style>
