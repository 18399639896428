<template>
  <a-tabs @change="handleChange" :activeKey="active">
    <a-tab-pane v-for="lge in languages" :key="lge.lge_id">
      <span slot="tab">
        <img class="anticon" :src="'/resources/images/icons/' + lge.lge_abbreviation + '.svg'">
        {{ lge.lge_name }}
      </span>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
export default {
  name: 'language-tab',
  computed: {
    languages: function() {
      return this.$store.getters['language/items']
    },
    active: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    handleChange(activeKey) {
      this.$store.commit('language/SET_ACTIVE', activeKey)
    },
  },
}
</script>

<style lang="scss" module>

</style>
